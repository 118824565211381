import React from "react"
import styled from "styled-components"
import { HERBS, PRIVACY_POLICY, AILMENTS, USER_AGREEMENT } from "../routes"
import {
  theme,
  PaddingLeftRightDesktop,
  BreakpointTablet,
  FacebookLink,
  InstagramLink,
  YouTubeLink,
  TwitterLink,
} from "../GlobalStyle"
import { Link } from "gatsby"
import moment from "moment"

const Container = styled.footer`
  background: #343e50;
  height: inherit;
  ${props => props.hideFooter? "display: none;": "display: initial;"}

  .company-and-social-column {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 200px;
  }
  .company-and-social-row {
    width: 100%;
    margin: auto;
    padding-left: var(--paddingLeftRightBlog);
    padding-right: var(--paddingLeftRightBlog);
    max-width: var(--maxPageWidthBlog);
  }

  .company-and-social-wrapper {
    display: flex;
    justify-content: space-around;
  }

  .social-wrapper {
    display: flex;
    padding-top: 20px;
    align-items: center;

    svg {
      height: 25px;
      opacity: 0.8;
      fill: white;

      :hover {
        opacity: 1;
      }
    }
  }

  .footers-footer {
    margin: auto;
    background: #2b333f;

    .ff-wrapper {
      display: flex;
      margin: auto;
      flex-direction: column;
      margin-top: 20px;
      padding-top: 10px;
      padding-bottom: 10px;

      padding-left: var(--paddingLeftRightBlog);
    padding-right: var(--paddingLeftRightBlog);
    max-width: var(--maxPageWidthBlog);
      font-size: 12px;
      color: white;
      justify-content: space-between;

      .footer-left,
      .footer-right {
        font-family: var(--Font1);
        font-weight: lighter;
      }

      .footer-right {
        font-size: 12px;
      }
    }
  }

  .footer-wrapper {

    padding-left: var(--paddingLeftRightBlog);
    padding-right: var(--paddingLeftRightBlog);
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    margin: auto;
    max-width: var(--maxPageWidthBlog);

    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: 1100px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (max-width: ${BreakpointTablet + "px"}) {
      padding-top: 20px;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  .company-and-social-column {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 200px;
    padding-top: 17.906px;
  }

  .social-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: inherit;
    padding-top: 30px;
    padding-bottom: 30px;

    a {
      color: white;

      cursor: pointer;
      img {
        opacity: 0.8;

        :hover {
          opacity: 1;
        }
      }
    }
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    .social-wrapper {
      width: 200px;
      display: grid;
      grid-template-columns: 25px 25px 25px 25px;
      grid-gap: 5px;
      padding-top: 10px;
      height: 70px;
      padding-bottom: 0px;
    }

    .footer-wrapper {
      padding-bottom: 20px;
    }
  }
`

const FooterColumn = styled.div`
  .footer-title {
    font-family: var(--Font1);
    font-weight: normal;
    text-transform: uppercase;
    color: white;
    font-size: medium;
  }

  a {
    color: white;
  }

  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
    margin-top: 0px;

    li {
      font-size: small;
      font-family: var(--Font2);
      color: white;
      a {
        color: white;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`

function Footer({hideFooter = false}) {
  return (
    <Container hideFooter={hideFooter}>
      <div className="company-and-social-row">
        <div className="company-and-social-column">
          <div className="social-wrapper">
            <FacebookLink />
            <InstagramLink />
            <TwitterLink />
          </div>
        </div>
      </div>
      <div className="footer-wrapper">
        <FooterColumn id="FooterColumnSecond">
          <div className="footer-title">Discover</div>
          <ul>
            <li>
              <Link to={HERBS}>Herbs</Link>
            </li>
            <li>
              <Link to={AILMENTS}>Ailments</Link>
            </li>
          </ul>
        </FooterColumn>

        <FooterColumn>
          <div className="footer-title">LEGAL</div>
          <ul>
            <li>
              <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={USER_AGREEMENT}>User Agreement</Link>
            </li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <div className="footer-title"> Contact </div>
          <ul>
            <li>
              <a href={"mailto:findanherb@gmail.com"}>findanherb@gmail.com </a>
            </li>
          </ul>
        </FooterColumn>
      </div>
      <footer className="footers-footer">
        <div className="ff-wrapper">
          <div className="footer-left">
            © {moment().format("YYYY")} FindAnHerb All Rights Reserved.
          </div>
          <div className="footer-right">
            FindAnHerb does not provide medical advice, diagnosis or treatment.
          </div>
        </div>
      </footer>
    </Container>
  )
}

export default Footer
