import PropTypes from 'prop-types'


export const UserAuthorPropTypes = PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    bio: PropTypes.string,
    website: PropTypes.string,
    avatar: PropTypes.string.isRequired,
    mongodb_id: PropTypes.string,
    id: PropTypes.string,
    _id: PropTypes.string,

  })