import React, {useState, useEffect, useContext} from 'react'
import ReactInterval from 'react-interval';
import styled from 'styled-components'
import moment from 'moment'
import { BreakpointMobile } from '../Layout/GlobalStyle';
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import AuthUserContext from '../../providers/AuthProvider';


const useStyles = makeStyles(theme => ({
    CTA: {
      fontFamily: "Roboto",
      textTransform: "initial",
      fontWeight: "regular",

    },
    activeHeart: {
      color: "#e91e63",
    },
    loadMoreRemediesButton: {
      fontFamily: "var(--Font1NotCondensed)",
      fontWeight: "bold",
      marginTop: 20,
      textTransform: "initial",
    },
  }))

const Container = styled.div`
    background: #444444;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 300px;
    margin-top: ${({marginTop = "20px"}) => marginTop};
    margin-bottom: 20px;

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        width: 100%;
        height: 280px;
        margin-left: auto;
    margin-right: auto;
        max-width: ${BreakpointMobile + 'px'};
    }
`

const Message = styled.p`
    font-size: 23px;
    font-family: var(--Font1NotCondensed);
    line-height: 30px;
    color: white;

    b{
        color: #12AA97;
    }

    text-shadow: 0px 3px 6px rgba(0, 0, 9, 0.16);
`

const CountdownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    position: relative;
    height: 65px;
    font-weight: 500;

`

const TimeSection = styled.div`
 
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    color: white;

    .time-amount{
        font-size: 42px;
        text-align: center;
        line-height: 42px;
        font-weight: bold;
        font-family: var(--Font1);
        text-shadow: 0px 3px 6px rgba(0, 0, 9, 0.16);
    }

    .time-label{
        font-size: 11px;
        height: 20px;
        font-weight: bold;
        font-family: var(--Font1);
        color: white;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);


    }

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        width: 250px;

        .time-amount{
            font-size: 30px;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
            font-family: var(--Font2);
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        }

        .time-label{
            font-size: 13px;
            height: 20px;
            text-transform: uppercase;
            text-align: center;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        }

    }
`

function CountDownBanner({marginTop}) {
    const {openSignInModal} = useContext(AuthUserContext)
    const classes = useStyles()
    const [countdown, setCountDown] = useState("")
    const [secondsLeft, setSecondsLeft] = useState(0)
    const [countDownActive, setCountDownActive] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(0)
    const [secondsRemaining, setSecondsRemaining] = useState(0)
    const [daysLeft, setDaysLeft] = useState(0)
    const [minutes, setMinutes] = useState(0)

    useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed){
            countDownInit()
        }
        
        return () => {
            isSubscribed = false;
        }
    }, [])

    const countDownInit = () => {
        var future = new Date();
        future.setDate(future.getDate() + 30);
        setCountDown(future)



        let today = moment()
        let secondsRemaining =  moment(countdown).diff(today, 'seconds')
        setSecondsLeft(secondsRemaining)
        setCountDownActive(true)

    }





    const handleCountDown = () => {
        let beginningTime =  moment(countdown);
        let end = moment()
        var duration = beginningTime ? moment.duration(beginningTime.diff(end)) : false;
        var seconds = duration ? duration.asSeconds() : false;
        if (seconds) {

            var days        = Math.floor(seconds/24/60/60);
            var hoursLeft   = Math.floor((seconds) - (days*86400));
            var hours       = Math.floor(hoursLeft/3600);
            var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
            var minutes     = Math.floor(minutesLeft/60);
            var remainingSeconds = seconds % 60;
            setDaysLeft(days)
            setHoursLeft(hours)
            setMinutes(minutes)

            setSecondsRemaining(remainingSeconds)
            seconds = Math.floor(seconds)
          setSecondsLeft(seconds)
        }
      }
    return (
        <Container marginTop={marginTop}>

                <ReactInterval timeout={1000} enabled={countDownActive} callback={() => handleCountDown()} />

        <CountdownContainer>
                <TimeSection>
                    <div className="time-amount">
                        
                        {daysLeft}
                    </div>
                    <div className="time-label">
                        Days
                    </div>
                </TimeSection>
                <TimeSection>
                    <div className="time-amount">
                        :
                    </div>
                    <div className="time-label">

                    </div>
                </TimeSection>
                <TimeSection>
                    <div className="time-amount">
                        {Math.floor(hoursLeft)}
                    </div>
                    <div className="time-label">
                        Hours
                    </div>
                </TimeSection>
                <TimeSection>
                    <div className="time-amount">
                        :
                    </div>
                    <div className="time-label">

                    </div>
                </TimeSection>
                <TimeSection>
                    <div className="time-amount">
                        {minutes}
                    </div>
                    <div className="time-label">
                        Minutes
                    </div>
                </TimeSection>
                <TimeSection>
                    <div className="time-amount">
                        :
                    </div>
                    <div className="time-label">

                    </div>
                </TimeSection>
                <TimeSection className="highlighted-time">
                    <div className="time-amount">
                        {Math.floor(secondsRemaining)}
                    </div>
                    <div className="time-label">
                        Seconds
                    </div>
                </TimeSection>

            </CountdownContainer>

            <Message>
                Sign up to get  <b>full access</b> to our collection of <b>Natural Remedies</b>.
            </Message>

            <footer>
                <Button onClick={() => openSignInModal("get full access to our natural remedies")} disableElevation variant={"contained"} color="secondary" className={classes.CTA}>
                    Click here to get started
                </Button>
            </footer>
            
        </Container>
    )
}



export default CountDownBanner