import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Header from './Header';
import Footer from './Footer';
import { GlobalStyle, theme, muiTheme } from '../GlobalStyle';
import SEO from '../SEO'
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import {useLocation} from '@reach/router'
import queryString from 'query-string'
import { seoPropTypes } from '../../../../types/SeoPropTypes';
import { AuthProvider } from '../../../providers/AuthProvider';
import { ErrorsProvider } from '../../../providers/ErrorsProvider';
import isEmpty from '../../../utils/isEmpty';


export function getJWT() {
    if (typeof window != undefined) {
        return localStorage.getItem("jwt")
            ? localStorage.getItem("jwt")
            : "";
    }
}
export const getHeaders = () => {
    if (typeof window != "undefined") {
        let headers = {
            Authorization: getJWT(),
            "Content-Type": "application/json",
        }

        return {
            headers
        }
    }
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;

  :root{
    --maxPageWidthBlog: 1400px;
    --paddingLeftRightBlog: 5%;
  }
`


const Wrapper = styled.div`
    display: block;
    width: 100%;
`

const Content = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${props => props.hideHeader? "padding-top: 0px;": "padding-top: 50px;"}

  height: inherit;
`

const BlogLayout = ({ children, seoProps, contentStyleProps }) => {

  const location = useLocation()
  let search = location.search ? queryString.parse(location.search) : {}

  search.hideHeader = !isEmpty(search.hideHeader) ? search.hideHeader : false;
  search.hideFooter = !isEmpty(search.hideFooter) ? search.hideFooter : false;

  if(search.hideHeader === "true"){
      search.hideHeader = true;
  }

  if(search.hideFooter === "true"){
    search.hideFooter = true;
}


    return (
        <>
            <SEO {...seoProps} />
            <ThemeTopLayout theme={muiTheme}>
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                    <ErrorsProvider>
                        <AuthProvider>
                            <Container>
                                <Header hideHeader={search.hideHeader} />
                                <Content hideHeader={search.hideHeader} {...contentStyleProps} >
                                    <Wrapper>
                                        {children}
                                    </Wrapper>
                                </Content>
                                <Footer hideFooter={search.hideFooter}  />
                            </Container>
                        </AuthProvider>
                    </ErrorsProvider>
                </ThemeProvider>
            </ThemeTopLayout>
            {/* <CustomerChat /> */}
        </>
    );
};


BlogLayout.defaultProps = {
    contentStyleProps: {
        paddingLeft: "3%",
        paddingRight: "3%"
    }
}

BlogLayout.propTypes = {
    contentStyleProps: PropTypes.shape({
        paddingLeft: PropTypes.string,
        paddingRight: PropTypes.string
    }),
    children: PropTypes.node.isRequired,
    seoProps: seoPropTypes
}





export default BlogLayout;
