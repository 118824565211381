import React, { Suspense, lazy } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GlobalStyle, BreakpointMobile } from "../../Layout/GlobalStyle"
import FallbackSmallProjectItem from "../../FallbackSmallProjectItem"
const SmallArticleItem = lazy(() => import("../SmallArticleItem"))

export const MostRecentArticlesContainer = styled.div`
  .most-recent-articles-label {
    font-size: 20px;
    font-family: var(--Font1);
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 0px;
    line-height: 32px;

    .highlight {
      color: var(--Color2);
    }
  }

  .recent-articles-column {
    display: grid;
    grid-gap: 20px;
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    .most-recent-articles-label {
      font-size: 20px;
      font-family: var(--Font1);
      text-transform: uppercase;
      margin-bottom: 15px;
      margin-top: 0px;

      font-size: x-large;
    }
  }
`

function RecentArticles() {
  const data = useStaticQuery(graphql`
    query {
      allMongodbHerbsBlogarticles(filter: {safeToPublish: {eq: true}, draft: {eq: false}}, limit: 5) {
        edges {
          node {
            id
            mongodb_id
            ogImage1200x628
            safeToPublish
            slug
            sticky
            title
            markup
            metaDescription
            modified_at
            draft
          }
        }
      }
    }
  `)

  let wordpressPosts = data.allMongodbHerbsBlogarticles.edges
  let renderPosts = wordpressPosts.map((item, index) => (
    <Suspense fallback={<FallbackSmallProjectItem />}>
      <SmallArticleItem key={index} {...item.node} />
    </Suspense>
  ))

  return (
    <MostRecentArticlesContainer>
      <h4 className="most-recent-articles-label"> Most Recent Articles</h4>
      <div className="recent-articles-column">{renderPosts}</div>
    </MostRecentArticlesContainer>
  )
}

export default RecentArticles
