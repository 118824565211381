import styled from 'styled-components'
import { BreakpointMobile, BreakpointTablet } from '../Layout/GlobalStyle'



export const GridLayoutOne = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 70px;
  max-width: var(--PageMaxWidth);

  @media only screen and (max-width: ${BreakpointTablet + 300 + "px"}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`


export const GridArticleLayoutOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
`

export const BlogContainer = styled.div`
    margin: auto;
    width: 100%;
    max-width: var(--maxPageWidthBlog);
    padding-left: var(--paddingLeftRightBlog);
    padding-right: var(--paddingLeftRightBlog);
    .recent-articles-grid{
        display: grid;
    }
`

export const CustomH4One = styled.h4`
    font-size: 34px;
    margin-top: 0px;
    line-height: 34px;
    text-transform: uppercase;
    font-weight: bold;

    @media only screen and (max-width: ${BreakpointMobile + 'px'}){
        font-size: 27px;
    }
`

export const MostRecentArticlesContainer = styled.div`
  .most-recent-articles-label {
    font-size: 20px;
    font-family: var(--Font1);
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 0px;
    line-height: 32px;

    .highlight {
      color: var(--Color2);
    }
  }

  .recent-articles-column {
    display: grid;
    grid-gap: 20px;
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    .most-recent-articles-label {
      font-size: 20px;
      font-family: var(--Font1);
      text-transform: uppercase;
      margin-bottom: 15px;
      margin-top: 0px;

      font-size: x-large;
    }
  }
`