import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import SEO from '../components/Layout/SEO'
import Ailment from '../components/Ailment'
import { AILMENT, BLOG } from '../components/Layout/routes'
import BlogArticle from '../components/Blog/BlogArticle'
import BlogLayout from '../components/Layout/BlogLayout'

 function BlogArticlePage(props) {
     let {articleData} = props.pageContext;
     let {slug, title, ogImage1200x628, metaDescription, date, author} = articleData
     const articleDescription = metaDescription ? metaDescription.substring(0, 150) : "This article has no description";
     const blogArticlePath = `${BLOG}/${slug}`
    const seoProps = {
        title: `${title}`,
        description: articleDescription,
        type: "article",
        pathname: blogArticlePath,
  
        articleMetaData: {
            articleSection: "",
            articleAuthor: "",
            articlePublishedTime: "",
            articleTag: "",
        },
        image: {
            width: 1200,
            height: 628,
            src: ogImage1200x628
        }
    }

    return (
        <BlogLayout seoProps={seoProps}>
            <BlogArticle articleData={articleData} />
        </BlogLayout>
    )
}

BlogArticlePage.defaultProps = {
    query: {
        slug: ''
    }
}

BlogArticlePage.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

// export const ailmentQuery = graphql`
//   query($id: String!) {
//     mongodbHerbsAilments(id: {eq: $id}) {
//     slug
//     name
//     description
//     ogImage1200x628
//     mongodb_id
//     internal {
//           content
//         }
//     }
//   }


export default BlogArticlePage

// article - Namespace URI: https://ogp.me/ns/article#

// article:published_time - datetime - When the article was first published.
// article:modified_time - datetime - When the article was last changed.
// article:expiration_time - datetime - When the article is out of date after.
// article:author - profile array - Writers of the article.
// article:section - string - A high-level section name. E.g. Technology
// article:tag - string array - Tag words associated with this article.