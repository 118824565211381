import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import moment from "moment"
// import socialBackground from '../../images/content/utah-content-creation-m10-marketing.jpg';
// import DisplayAdCTA from '../common/DisplayAdCTA'
import RecentArticles from "./RecentArticles"
import AuthorSection from "./AuthorSection"
// import RecentProjectsColumn from '../RecentProjectsColumn'
// import ProjectsByBlogCategoryColumn from '../ProjectsByBlogCategoryColumn'
import PostFooter from "./PostFooter"
import PropTypes from "prop-types" // ES6
import CountDownBanner from "../../CountDownBanner/CountDownBanner"
import {
  BreakpointMobile,
  BreakpointTablet,
  theme,
} from "../../Layout/GlobalStyle"
import { GridLayoutOne } from "../styles"
import ShareBar from "../../common/ShareBar"
import { getAuthorByUserId } from "../../../services/user.services"
import AuthUserContext from "../../../providers/AuthProvider"
import SignUpMessageBarWithCount from "../../common/SignUpMessageBarWithCount"
import Advertisment from "../../Advertisement"
import { AUTHOR } from "../../Layout/routes"
// import RecentShareGalleryColumn from '../RecentShareGalleryColumn'
// import ShareBar from '../../common/ShareBar'

const StickyColumn = styled.div`
  height: 80%;
  top: 0px;
  .featured-content-column {
    padding-top: 40px;
  }
`

export const WordpressContent = styled.div`
  width: 100%;
  position: relative;

  h1 {
    font-size: 28px;
    line-height: 32px;
    font-family: var(--Font1);
    color: rgb(34, 34, 32);
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    font-family: var(--Font1);
    color: rgb(34, 34, 32);
  }

  p {
    font-family: var(--Font2);
    font-size: 16px;
    line-height: 24px;
    color: rgb(77, 77, 77);
  }

  ul,
  ol {
    border: 1px solid blue;
    margin-left: 0px;
    padding-left: 0px;
    font-size: 16px;
    font-family: var(--Font2);
    color: rgb(77, 77, 77);

    li {
      padding-left: 0px;
    }

    b {
      color: rgb(34, 34, 32);
    }
  }

  ul {
    list-style-type: none !important;
    list-style-position: outside;
    margin-left: 20px;

    li {
      padding-left: 0px !important;
      list-style-image: url("https://res.cloudinary.com/landonwebdev/image/upload/v1611758736/findanherb/bullet-point-icon.svg") !important;
    }
  }

  a {
    color: #eb6848;
  }
`

const Container = styled.article`
  padding-top: var(--PaddingContentTop);
  padding-left: var(--paddingLeftRightBlog);
  padding-right: var(--paddingLeftRightBlog);
  width: 100%;
  max-width: var(--maxPageWidthBlog);
  margin: auto;

  .post-author-and-date {
    display: flex;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-family: var(--Font2);
    font-size: 16px;
  }
  .post-date {
    /* margin-bottom: 40px; */
  }

  header {
    margin-top: 0px;
  }

  .post-header {
    padding-bottom: 20px;
    width: 100%;
  }

  .post-title {
    font-size: 50px;
    line-height: 60px;
    font-family: var(--Font1);
    margin-bottom: 10px;
  }

  .article-featured-image {
    width: 100%;
    margin: 0px;
    border-radius: 4px;
    margin-bottom: 40px;
    img {
      border: 1px solid rgba(0, 0, 0, 0.07);
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.01);
    }
    figcaption {
      text-align: center;
      font-size: small;
      line-height: 13px;
      opacity: 0.5;
      font-size: 11px;
      font-style: italic;
    }
  }

  .post-content {
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    h1,
    h2 {
      font-family: var(--Font1);
      margin-bottom: 5px;
    }

    h2 {
      margin-top: 30px;
      font-size: 27px;
      margin-bottom: 5px;
    }

    h5,
    h4 {
      line-height: 25px;
      font-size: 16px;
      margin-bottom: 0px;
    }

    h3 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 7px;
    }

    h5 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 7px;
    }

    h4 {
      margin-bottom: 0px;
    }

    ul {
      margin-top: 0px;
      margin-left: 20px;
      font-size: 15px;
    }

    h6 {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 7px;
    }

    p {
      margin-top: 0px;
      font-family: var(--Font2);
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 15px;
    }

    figure {
      margin-left: 0px;
    }

    @media only screen and (max-width: ${BreakpointTablet + "px"}) {
      .wp-block-embed-youtube {
        margin: auto;
        width: 100%;
        iframe {
          width: 90vw;
          height: 45vw;
        }
      }
    }
  }

  .post-date {
    color: #888;
  }

  .post-author-date-dash {
    padding-left: 5px;
    padding-right: 5px;
  }

  .post-author {
    b {
      color: black;
    }
    :hover {
      text-decoration: underline;
    }
  }

  .post-columns {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 70px;
    max-width: var(--PageMaxWidth);
  }

  .post-column {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    .post-title {
      font-size: 40px;
      line-height: 50px;
    }

    .post-columns {
      display: flex;
      flex-direction: column;
    }

    .post-content {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    .post-title {
      line-height: 35px;
      font-size: x-large;
    }

    .post-author-date-dash {
      display: none;
    }

    h2 {
      line-height: 35px;
      font-size: x-large !important;
      margin-bottom: 0px;
    }

    h3 {
      font-size: large !important;
    }

    h4 {
      font-size: medium important;
    }

    .post-author-and-date {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-family: var(--Font2);
      font-size: 12px;
    }
  }
`

export const DividerLine = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  width: inherit;
  height: 1px;
  background: #ccc;
`

function BlogArticle(props) {
  const {
    triggerVisitToWebsiteWithOutMembership,
    user,
    remainingVisitsToWebsite,
    openSignUpToBecomeAMember,
  } = useContext(AuthUserContext)
  const [loadingAuthor, setLoadingAuthor] = useState(false)
  const [author, setAuthor] = useState({
    _id: "",
    first_name: "",
    last_name: "",
    avatar: "",
    bio: "",
  })
  const { articleData } = props
  const {
    ogImage1200x628,
    markup,
    author: authorId,
    date,
    nextSlug,
    nextPrev,
    nextFeaturedMedia,
    title,
    nextTitle,
    metaDescription,
    slug,
  } = articleData

  // const categoriesToHashTags = categories.forEach((cat) => hashtags.push(cat.name))

  let limitedExcerpt = metaDescription
    .replace(/<p[^>]*>/g, "")
    .replace("</p>", "")
    .replace("&#8217;", "'")
    .substring(0, 155)
  let sourceURL = ogImage1200x628
  let altText = "s"
  let hashtags = ["findAnHerb", "madeinthe801"]

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && authorId) {
      loadAuthor(isSubscribed)
    }

    return () => (isSubscribed = false)
  }, [authorId])

  const loadAuthor = () => {
    setLoadingAuthor(true)
    getAuthorByUserId(authorId)
      .then(res => {
        if (res.status >= 200) {
          setLoadingAuthor(false)
          setAuthor(res.data)
          triggerVisitToWebsiteWithOutMembership(slug)
        }
      })
      .catch(err => {
        setLoadingAuthor(false)

        throw err
      })
  }

  // to do
  // get herb list by blog article id
  // get ailment list by blog article id
  // get products list by products id
  return (
    <>
      <Container>
        <SignUpMessageBarWithCount
          callbackIfZero={() => openSignUpToBecomeAMember()}
          visible={user.id ? false : true}
          countLeft={remainingVisitsToWebsite}
        />

        <header className="post-header">
          <h1 className="post-title">{title}</h1>
          <div className="post-author-and-date">
            {author && author._id ? (
              <Link to={`${AUTHOR}/${author._id}`}>
                <div className="post-author">
                  By <b>{`${author.first_name} ${author.last_name}`}</b>
                </div>
              </Link>
            ) : null}
            <span className="post-author-date-dash"> - </span>
            <time className="post-date">
              {moment(date).format("MMMM DD, YYYY")}
            </time>
          </div>
          <ShareBar media={sourceURL} title={title} hashtags={hashtags} />
        </header>

        <GridLayoutOne style={{ paddingBottom: 50 }}>
          <div className={"post-column"}>
            <header>
              <figure className="article-featured-image">
                <img
                  alt={altText}
                  height="auto"
                  width={"100%"}
                  src={sourceURL || theme.defaultWordpressArticleImage}
                />
                <figcaption>{altText}</figcaption>
              </figure>
            </header>
            <WordpressContent
              className="post-content"
              dangerouslySetInnerHTML={{ __html: markup }}
            ></WordpressContent>
            <br />
            <ShareBar media={sourceURL} hashtags={hashtags} title={title} />
          </div>
          <div className="post-column">
            <StickyColumn>
              <CountDownBanner marginTop={"0px"} />
              {/* <DisplayAdCTA footerText={"See what we do"} backgroundImage={socialBackground} gradient={['rgba(186, 97, 40, 1)', 'rgba(14, 112, 184, 1)']} /> */}
              <div className="featured-content-column">
                <RecentArticles />
                <br />
                <Advertisment />
                <br />

                {/* <RecentProjectsColumn /> */}

                <br />
                <br />

                {/* <RecentShareGalleryColumn /> */}
              </div>

              {/* <ProjectsByBlogCategoryColumn categories={categories} /> */}
            </StickyColumn>
          </div>
        </GridLayoutOne>
        <DividerLine />
        {author && author.first_name ? (
          <AuthorSection large={false} author={author} />
        ) : null}
        <br />
      </Container>
      {nextFeaturedMedia ? (
        <PostFooter
          title={nextTitle}
          slug={nextSlug}
          media={nextFeaturedMedia}
        />
      ) : (
        <>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </>
  )
}

BlogArticle.propTypes = {
  articleData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string,
    ogImage1200x628: PropTypes.string,
    date: PropTypes.string,
    author: PropTypes.string,
  }),
  nextTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nextSlug: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nextFeaturedMedia: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default BlogArticle
