import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { BreakpointMobile } from '../../Layout/GlobalStyle'
import { AUTHOR } from '../../Layout/routes'
import { UserAuthorPropTypes } from '../../../../types/UserAuthorPropTypes'
import PropTypes from 'prop-types'
const Container = styled.div`
    display: flex;
    .author-name{
        font-size: ${props => props.large? 'x-large' : '15px'};
        font-family: var(--Font1);
        font-weight: 500;
        line-height: ${props => props.large? '20px' : '15px'};

        font-weight: bold;
        color: black;
        height: ${props => props.large? '30px' : '20px'};


        a{
            color: black;
        }
        :hover{
            text-decoration: underline;
        }
    }

    .author-info{
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        a{
            height: inherit;
        }
    }

    .author-name-and-website{
        display: flex;
        flex-direction: column;
       
    }

    .author-bio{
        margin-top: 0px;
        font-size: small;
        font-family: var(--Font2);
        font-size: ${props => props.large? 'medium;' : 'small;'};
        line-height: ${props => props.large? '25px;' : '17px;'};

    }

    .authors-website{
        font-size: ${props => props.large? 'small;' : '10px;'};


        :hover{
            text-decoration: underline;
        }
    }

    @media  (max-width: ${BreakpointMobile + 'px'}){
        flex-direction: column;
        .author-info{
            padding-left: 0px;
            text-align: center;
        }

        .author-name{
            display: flex;
            justify-content: center;
            font-size: large;
            align-items: center;

            a{
                display: inherit;
                justify-content: inherit;
                align-items: inherit;
                font-size: inherit;
            }
        }

        .avatar-wrapper{
            display: flex;
            justify-content: center;
            
            img{
                margin: auto;
            }
        }
 
        .author-bio{
            font-size: small;
        }
    }
`



 function AuthorSection({large = false, author: {first_name, last_name, avatar, website, bio, _id}}) {

    const name = `${first_name} ${last_name}`
    return (
        <Container large={large}>
            <div className="avatar-wrapper">
                <Link to={`${AUTHOR}/${_id}`}>
                    <img style={{ borderRadius: 4 }} alt={`${first_name} ${last_name}`} width={96} height={96} src={avatar} />
                </Link>

            </div>
            <div className="author-info">
                <div className="author-name-and-website">
                    <b className="author-name">
                    <Link to={`${AUTHOR}/${_id}`}>
                        {name}
                    </Link> 
                    </b>
                {website? 
                 <div className="authors-website">
                    <OutboundLink target={"_blank"} href={website}>
                       {website}
                    </OutboundLink>
                    </div>
                     : ""
                }
                </div>
                <p className="author-bio">
                    {bio? bio : "Here is some text"}
                </p>
            </div>

            
        </Container>
    )
}



AuthorSection.propTypes = {
    author: UserAuthorPropTypes,
    large: PropTypes.bool.isRequired,
}

export default AuthorSection

