import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import { BreakpointMobile } from '../../../Layout/GlobalStyle'
import { BLOG_POST } from '../../../Layout/routes'




const Container = styled.div`
  width: 100%;
  height: 350px;
  position: relative;
  background-image: url(${props => props.hasMedia});
  background-size: 100%;
  transition: 0.2s ease-in-out;
  
  .next-and-title-wrapper{
    max-width: var(--PageMaxWidth);
    margin: auto;
    height: inherit;
    z-index: 490;
    position: relative;
  }

  .inner{
    text-align: center;
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }


  .overlay-footer{
    position: absolute;
    z-index: 480;
    width: 100%;
    height: inherit;
    background: #282b30;
    opacity: 0.7;
    transition: 0.2s ease-in-out;
 
  }

  .inner-text{
    color: white;
    span{
      display: block;
      color: #fff;
      font-family: var(--Font2);
      font-size: 16px;
      line-height: 16px;
    }
    h3{
      font-family: var(--Font1);
      margin-top: 0px;
      margin-bottom: 0px;
      vertical-align: baseline;
      font-size: 38px;
      :hover{
        text-decoration: underline;
      }
    }
  }

  :hover{
    background-size: 101%;
    .overlay-footer{
      transition: 0.1s;
      opacity: 0.5;
    }
  }


  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    .inner-text{
      h3{
        font-size: xx-large;
        line-height: 50px;
      }
    }
  }

  
`




 function PostFooter({title, slug, media}) {
  return (
    <Container hasMedia={media}>
      <div className="overlay-footer" />
      <div className="next-and-title-wrapper">
        <div className="inner">
          <Link to={`${BLOG_POST}/${slug}`}>
          <div className="inner-text">
            <span>
            <i>Next Post</i>
            </span>
            <h3 className="article-title">
              {title}
            </h3>
          </div>
          </Link>
        </div>
      </div>
    </Container>
  )
}



PostFooter.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  media: PropTypes.string.isRequired
}


export default PostFooter