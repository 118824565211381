import React from "react"
import styled from "styled-components"
import { BreakpointMobile } from "../Layout/GlobalStyle"


export const Container = styled.div`
  display: flex;
  height: 70px;

  .loading-row-one {
    background: ${props => props.theme.webWashColor};
    width: 60px;
    height: 6px;
    border-radius: 4px;
  }

  .entire-meta-link {
    display: flex;
    width: 100%;
  }

  .loading-rows-wrapper {
    padding-top: 10px;
    padding-left: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .loading-row-two {
    border-radius: 4px;
    background: ${props => props.theme.webWashColor};
    height: 6px;

    margin-top: 12px;
    width: 100px;
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    height: initial;
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    flex-direction: column;

    .loading-row-one,
    .loading-row-two {
      height: 13px;
    }

    .loading-row-one {
      width: 20%;
    }
    .loading-row-two {
      width: 60%;
    }

    .loading-rows-wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 0px;
    }
  }
`

export const ImagePlaceHolderWrap = styled.div`
  display: block;
  min-width: 65px;
  min-height: 65px;
  border-radius: 4px;
  overflow: hidden;

  .image-source {
    background-position: 50%;
    min-width: 65px;
    background: ${props => props.theme.webWashColor};
    background-size: cover;
    min-height: 65px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.01);
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: auto;

    .image-source {
      min-width: inherit;
      max-width: inherit;
      height: 200px;
    }
  }
`

function FallbackSmallProjectItem() {
  return (
    <Container>
      <ImagePlaceHolderWrap>
        <div className="image-source loading-gradient" />
      </ImagePlaceHolderWrap>
      <div className="loading-rows-wrapper">
        <div className="loading-row-one"></div>
        <div className="loading-row-two"></div>
      </div>
    </Container>
  )
}

export default FallbackSmallProjectItem
